@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.cdnfonts.com/css/alegreya');
@import url('https://fonts.cdnfonts.com/css/irish-grover');

.Alegreya{
  font-family: 'Alegreya', sans-serif;
}
.Irish{
  font-family: 'Irish Grover', sans-serif;
                                                
}

body{
  background-image: url(./assets/background.png);
  background-size: cover;
  background-color: red;
  background-position: center;
  background-attachment: fixed; 
}


/* Scrollbar */
.scrollbar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  background: #c01414;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: #15aa35;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #5716ac;
}




.loop-autoplay {
  widdth: 100%;
  height: auto;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loop-autoplay {
  animation: rotate 10s linear infinite; /* Adjust duration and timing function */
}